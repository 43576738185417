
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import { mapActions } from 'vuex'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { fixPrice, parseToNumber } from '@/utils/general'

@Component({
  components: { GAlert, GCostField, FieldTitle, RatingCell },
  methods: {
    ...mapActions('resources/form', ['findLeadActivity']),
  },
})
  export default class inspectionQualification extends BasePanel {
  @Prop({ type: String, default: null }) type: string
  @Prop({ type: Boolean, default: false }) isOver: string
  @Prop({ type: Boolean, default: false }) hidden: boolean
  @Prop({ type: Object, default: () => null }) config: Record<string, any>
  rating = 0
  message = ''
  agree = null
  cost = null
  errorMessageCost = ''
  alertNegotiation = {
    open: false,
    title: '',
    subtitle: '¿Está seguro de continuar?',
  }

  alert = {
    open: false,
    title: 'Debe calificar la inspección para continuar',
  }

  findLeadActivity!: (payload: number) => Promise<any>

  get isSupervisor () {
    return this.type === 'supervisor'
  }

  get isDiscountAvailable () {
    const { inspection } = this

    return !inspection?.supervisorMetadata?.validated
  }

  get isRequiredFields () {
    return !this.inspection?.metadata?.validated
  }

  get ratingLength () {
    const { inspection, config, isOver } = this

    if (inspection?.metadata?.progress < 100 || isOver) {
      return config?.max
    }

    return 5
  }

  get isValidated () {
    const { inspection } = this

    return inspection?.metadata?.validated
  }

  @Watch('sendQualification', { immediate: true })
  async onSendQualificationChange (val) {
    if (!val) return
    const { type, rating, errorMessageCost, isDiscountAvailable, isOver } = this

    if (errorMessageCost?.length && isDiscountAvailable && !isOver) {
      this.$emit('set-qualification')
      return
    }
    if (!rating) {
      this.alert.open = true
      this.rating = null
      this.$emit('set-qualification')
      return
    }

    if (type === 'inspector') {
      const { inspection } = this
      await this.putInspectorQualification(rating, inspection)

      const idActivity = await this.findLeadActivity(inspection.deal.lead.id)

      if (idActivity) {
        const close = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'closed' } } }] },
        })
        await this.pushData({
          model: 'LeadActivity',
          fields: { id: idActivity, activity_result: true, id_process_status: close?.[0]?.id },
        })
      }
    } else {
      if (this.isOver) {
        await this.confirmNegotiation()
      } else {
        const cost = !isDiscountAvailable ? this.inspection?.supervisorMetadata?.totalAmount : this.cost
        this.alertNegotiation = {
          open: true,
          title: `Se creará la negociación con
  Descuento de inspección: ${cost ? fixPrice(cost) : 0}
  Oferta compra ${fixPrice(this.inspection.appraisal.price - cost)}
  Oferta consignación ${fixPrice(this.inspection.appraisal.priceConsignment - cost)}`,
          subtitle: '¿Está seguro de continuar?',
        }
      return
      }
    }
    await this.close()
  }

  cancelNegotiationSend () {
    this.alertNegotiation.open = false
    this.$emit('set-qualification')
    this.$emit('loading-off')
  }

  async confirmNegotiation () {
    this.alertNegotiation.open = false
    const cost = !this.isDiscountAvailable ? this.inspection?.supervisorMetadata?.totalAmount : this.cost
    await this.putSupervisorQualification(this.rating, cost, this.isOver)
    await this.close()
  }

  @Watch('agree', { immediate: true })
  onAgreeChange (val) {
    this.$emit('allow-send', val)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionRatingChange (val) {
    const { type } = this
    if (!this.rating) {
    this.rating = type === 'inspector' ? val?.inspectorQualification : val?.supervisorQualification
    }
    if (val?.inspectorQualification) {
      this.agree = true
    }

    if (!this.cost) {
      this.cost = this.inspection?.supervisorMetadata?.authorizedAmount || this.inspection?.supervisorMetadata?.totalAmount || this.inspection?.metadata?.authorizedAmount
    }
  }

  get allowSend () {
    const { rating, cost } = this

    return {
      rating,
      cost,
    }
  }

  @Watch('allowSend', { immediate: true, deep: true })
  onAllowSendChange (val) {
    if (this.type === 'supervisor' && val?.rating && val?.cost && val?.agree) {
      this.$emit('allow-send', true)
    }
  }

  @Watch('rating', { immediate: true, deep: true })
  onConfigChange (val) {
    if (!val) {
      this.message = null
      return
    }

    if ((this.config?.max && val <= this.config?.max) || this.isOver) {
      this.message = `Con está calificación la inspección queda en "No califica", ¿Esta seguro que quiere enviarla?`
    } else {
      const messageInspector = `Con está calificación la inspección quedara "por validar", ¿Esta seguro que quiere enviarla?`
      const messageSupervisor = this.isRequiredFields ? `Con está calificación se generará la negociación, ¿Esta seguro que quiere enviarla?` : `Con está calificación la inspección queda "Validada" y se generará la negociación, ¿Esta seguro que quiere enviarla?`
      this.message = this.type === 'inspector' ? messageInspector : messageSupervisor
    }
  }

  get watchCost () {
    const { cost, rating } = this

    return {
      cost, rating,
    }
  }

  @Watch('watchCost', { immediate: true, deep: true })
  onCostChange (val) {
    if (this.type !== 'supervisor' || !val.rating) return

    if (parseToNumber(val.cost) < 0) {
      this.errorMessageCost = 'El campo no puede ser negativo'
      return
    }

    if (parseToNumber(val.cost) < parseToNumber(this.inspection?.supervisorMetadata.totalAmount)) {
      const cost = fixPrice(this.inspection?.supervisorMetadata.totalAmount)
      this.errorMessageCost = `El descuento no puede ser menor al costo de inspección validado ${cost}`
      return
    }

    this.errorMessageCost = ''
  }

  confirmAlert () {
    this.alert.open = false
    this.rating = null
  }
  }
